<i18n>
{
  "en": {
    "browser_title": "Series",
    "series": "Series"
  },
  "ja": {
    "browser_title": "シリーズ",
    "series": "シリーズ"
  }
}
</i18n>

<template>
  <main>
    <vue-headful v-bind:title="dynamicTitle" />
    <div id="series" class="page-margin">
      <div class="block-header">
        <div class="container">
          <h1>{{ $t('series') }}</h1>
        </div>
      </div>
      <series-list
        :hide-series="[]"
      />
    </div><!-- /#series -->
  </main>
</template>

<script>
import vueHeadful from 'vue-headful';
import APP_CONFIG from '@/appConfig';
import SeriesList from '@/components/series/seriesList.vue';

export default {
  components: {
    'vue-headful': vueHeadful,
    'series-list': SeriesList,
  },
  data() {
    return {
      dynamicTitle: '',
    };
  },
  mounted() {
    // set browser title
    this.dynamicTitle = `${this.$t('browser_title')} ${APP_CONFIG.site.title[this.locale]}`;

    // we have the title, now send the page view
    this.$analytics.trackPage(this.$route.fullPath, this.dynamicTitle);
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/sass/pages/_series.scss';
</style>
