<i18n>
{
  "en": {
    "series": "Series"
  },
  "ja": {
    "series": "シリーズ"
  }
}
</i18n>

<template>
  <div class="container">
    <div class="section">
      <ul class="grid is-button-list">
        <li
          class="grid-item"
          v-for="item in seriesList"
          :key="item.series_id"
          @click="clickTrack('Series Page', item.name.ja);"
        >
          <router-link :to="`/search/?sr=${item.series_id}`" class="list-button">
            {{ item.name[locale] }}
            <i class="iconf-arrow-right"></i>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
/* eslint no-console: 0 */
/* eslint max-len: 0 */

import BifrostAPI from '@/assets/js/services/Bifrost/API';

export default {
  props: {
    hideSeries: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      seriesList: [],
    };
  },
  async created() {
    const bfAPI = new BifrostAPI();

    const result = await bfAPI.getSeries();
    Object.keys(result).forEach((key) => {
      if (!this.hideSeries.includes(result[key].name.ja)) this.seriesList.push(result[key]);
    });
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
  },
  methods: {
    clickTrack(category, action, label = null, value = null) {
      this.$analytics.trackEvent(category, action, label, value);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
